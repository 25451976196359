import { TaskDetails, TaskSummaryList } from '../../leadsDetail/LeadsDetailRepository';
import { EnquiryDetail, EnquiryDetailCustomField } from '../models/Enquiry';
import '../../App.css';
import { TimezoneOption, } from '../../lookupConfiguration/selectors/TimezoneSelector';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import { MatterTerminology } from '../../MatterTerminology';
import { formatNumber } from '../../helpers/ChartHelper';
import html2canvas from 'html2canvas';
import { CustomFieldByHierarchy } from '../../enquiryCustomFields/CustomFieldRepositores';

const formatDate = (value: Date, format: string) => {
    return moment(value).format(format);
};

const setTitle = (title: string | null) => {
    if (title !== null && title !== '' && title !== 'None') {
        return '<span>' + title + '&nbsp;</span>';
    }

    return '';
};

const convertDate = (date: Date | null) => {
    if (date) {
        return moment(date).format('DD-MMM-YYYY');
    }
    return null;
};

const convertToLocalTimeZone = (date: string) => {
    var oldDate = new Date(date);
    var newDate = new Date(date);
    newDate.setMinutes(oldDate.getMinutes() - oldDate.getTimezoneOffset());
    return newDate;
};

const convertMentionInCommentToHtml = (comment: string, label?: string) => {
    let regex = /@\[.+?\]\(.+?\)/gm;
    let displayRegex = /@\[.+?\]/g;
    let idRegex = /\(.+?\)/g;
    let matches = comment.match(regex);
    // tslint:disable-next-line: no-any
    let arr: any = [];
    
    // tslint:disable-next-line: no-unused-expression
    matches &&
        // tslint:disable-next-line: no-any
        matches.forEach((m: any) => {
            let id = m.match(idRegex)[0].replace('(', '').replace(')', '');
            let display = m.match(displayRegex)[0].replace('@[', '').replace(']', '');

            arr.push({ id: id, display: display });
        });
    let newComment = comment.split(regex);
    let output = label ? label + ': ' : '';
    for (let i = 0; i < newComment.length; i++) {
        const c = newComment[i];
        if (i === newComment.length - 1) {
            output += c;
        } else {
            output += c + `<span mentioned_id="${arr[i].id}">${arr[i].display.split('|')[0]}</span>`;
        }
    }

    return output;
}; 
    const historyHtml = (enquiryDetail: EnquiryDetail, currency: string, timezone: TimezoneOption[], leadDetailsHistory: TaskSummaryList, customFieldByHierarchy?: CustomFieldByHierarchy[]) => {
    const styles = `
    .history-body {
        margin: 0;
        display: flex;
        flex-flow: column;
        font-family: 'Nunito', Arial, Helvetica, sans-serif;
        font-size: 10px;
    }
    .history-header {
        display: flex;
        align-items: center;
        flex-shrink: 1;
        padding: 15px 15px 0px 15px;
    }
    .history-header .divider {
        width: 1px;
        height: 100%;
        border-left: 1px solid #D9DFF7;
        margin: 0 20px;
    }
    .history-main {
        flex: 1;
        padding: 0 15px;
    }
    .logo {
        max-width: 200px;
        width: 100%;
        height: auto;
    }
    .logo-title {
        font-size: 22px;
        font-weight: 300;
    }
    .section {
        padding: 10px 0;
        max-width: 500px;
    }
    .section .details {
        display: flex;
    }
    .section-enquiry .details > div {
        display: flex;
    }
    .section-enquiry .detail {
        display: flex;
        flex-flow: column;
        flex: 1;
    }
    .section-enquiry .detail > div {
        display: flex;
    }
    .section-enquiry .detail-1 > div svg {
        width: 17px;
        height: 17px;
        margin-right: 5px;
    }
    .section-enquiry .detail-2 {
        align-items: flex-end;
    }
    .section-enquiry .detail-3 {
        align-items: flex;
    }
    .section-enquiry .detail > div {
        padding: 2.5px 0;
    }
    .section-title {
        font-size: 16px;
        font-weight: 400;
        color: #5173FF;
        margin: 0;
    }
    .subsection-title {
        font-size: 12px;
        font-weight: 400;
        color: #5173FF;
        margin: 5px 0;
    }
    .subsection-subtitle {
        font-size: 10px;
        font-weight: 400;
        color: #5173FF;
        margin: 5px 0;
    }
    .section-summary-content {
        display: flex;
        margin: 0 -10px;
    }
    .section-summary-content .summary-col {
        flex: 1;
        padding: 0 10px;
    }
    .section-summary-content .detail-form {
        display: flex;
        padding: 2.5px 0;
        margin: 0 -5px;
    }
    .section-summary-content .detail-form .detail-label {
        font-weight: 600;
        padding: 0 5px;
        /* flex: 1 */
    }
    .section-summary-content .detail-form .detail-value {
        font-weight: 400;
        padding: 0 5px;
        /* flex: 2; */
    }
    .section-history-content .history {
        padding: 0;
    }
    .section-history-content .history-header {
        margin: 0 -10px;
        display: flex;
    }
    .section-history-content .history-header > div {
        padding: 0 10px;
    }
    .section-history-content .history-title {
        font-weight: 600;
    }
    .history-content-description {
        color: #000000;
    }
    .detail-text {
        display: 'flex',
    }
    .detail-text-label {
        margin-right: 5px;
        font-weight: 600;
    }
    .lists {
        margin-bottom: 0;
    }
    .page-Break {
        page-break-after: always;
    }
    input[type='checkbox'] {
        margin: 0 !important;
        width: 16px;
    }
    .checkbox-group {
        justify-content: space-between;
    }
    .checkbox-group div {
        width: 200px;
    }
    .custom-field-item {
        margin-left: -20px;
    }
    `;

    const htmlElememnt = `
        <!DOCTYPE html>
        <html lang="en">
            <body class="history-body">                
                <main class="history-main">
                    <style>
                        ${styles}
                    </style>
                    <section class="section section-enquiry">
                        <h3 class="section-title">Enquiry Details</h3>
                        <div class="section-content details">
                            <div class="detail detail-1">
                                <div>${setTitle(enquiryDetail.title)}${enquiryDetail.firstName}&nbsp;${enquiryDetail.lastName}</div>
                                ${ enquiryDetail.email && enquiryDetail.email !== '' ?
                                `<div class="detail-text">
                                    <span class="detail-text-label">Email:</span>
                                    <span class="detail-text-value">${enquiryDetail.email.trim()}</span>
                                </div>` : ''}
                                ${enquiryDetail.phone && enquiryDetail.phone !== '' ? (
                                `
                                    <div class="detail-text">
                                        <span class="detail-text-label">Phone: </span>
                                        <span class="detail-text-value">${enquiryDetail.phone}</span>
                                    </div>
                                `) : ''}
                                ${enquiryDetail.mobile && enquiryDetail.mobile !== '' ? (
                                `
                                    <div class="detail-text">
                                        <span class="detail-text-label">Mobile:</span>
                                        <span class="detail-text-value">${enquiryDetail.mobile}</span>
                                    </div>
                                `) : ''}
                                <div class="detail-form">
                                    <div class="detail-text-label">Source: </div>
                                    <div class="detail-text-value">${enquiryDetail.source}</div>
                                </div>
                                ${enquiryDetail.referrerName && enquiryDetail.referrerName !== '' ? (
                                    `
                                        <div class="detail-text">
                                            <span class="detail-text-label">Referrer:</span>
                                            <span class="detail-text-value">${enquiryDetail.referrerName}</span>
                                        </div>
                                    `) : ''}
                                
                                ${enquiryDetail.areaOfLaw && enquiryDetail.areaOfLaw !== '' ? (
                                    `
                                    <div class="detail-form">
                                        <div class="detail-text-label">Area of Law:</div>
                                        <div class="detail-text-value">${enquiryDetail.areaOfLaw}</div>
                                    </div>
                                    `
                                ) : '' }
                                ${enquiryDetail.isClosed && enquiryDetail.outcomeDate ? (
                                    `
                                    <div class="detail-form">
                                        <div class="detail-text-label">Date Closed:</div>
                                        <div class="detail-text-value">${convertDate(enquiryDetail.outcomeDate)}</div>
                                    </div>
                                    `
                                ) : '' }
                                ${enquiryDetail.office && enquiryDetail.office !== '' ? (
                                    `
                                    <div class="detail-form">
                                        <div class="detail-text-label">Location:</div>
                                        <div class="detail-text-value">${enquiryDetail.office}</div>
                                    </div>
                                    `
                                ) : '' }
                            </div>
                            <div class="detail detail-2">
                                <div>Created ${formatDate(enquiryDetail.createdDate, 'DD MMM YYYY hh:mm a')}</div>
                                ${enquiryDetail.dateAssigned ? (
                                    `<div>Assigned ${formatDate(enquiryDetail.dateAssigned, 'DD MMM YYYY hh:mm a')}</div>`
                                ) : ''}
                            </div>
                        </div>
                        <div class="section-content details">
                            <div class="detail detail-3">
                                ${enquiryDetail.natureOfEnquiry && enquiryDetail.natureOfEnquiry !== '' ? (
                                    `
                                    <div class="detail-form">
                                        <div class="detail-text-label">Nature of Enquiry:</div>
                                        <div class="detail-text-value" style="text-align: justify;">${enquiryDetail.natureOfEnquiry}</div>
                                    </div>
                                    `
                                ) : '' }
                            </div>
                        </div>
                        <div class="section-content details">
                            <div class="detail detail-3">
                                ${enquiryDetail.sourceNotes && enquiryDetail.sourceNotes !== '' ?
                                    `<div class="detail-text">
                                        <span class="detail-text-label">Source Notes:</span>
                                        <span class="detail-text-value" style="text-align: justify;">${enquiryDetail.sourceNotes || 0}</span>
                                    </div>` : '' }
                                ${enquiryDetail.internalNotes && enquiryDetail.internalNotes !== '' ?
                                    `<div class="detail-text">
                                        <span class="detail-text-label">Internal Notes:</span>
                                        <span class="detail-text-value" style="text-align: justify;">${enquiryDetail.internalNotes || 0}</span>
                                    </div>` : '' }
                            </div>
                        </div>
                        <div class="section-content details">
                            <div class="detail detail-3">
                                ${enquiryDetail.eventAndCampaignName && enquiryDetail.eventAndCampaignName !== '' ?
                                    `<div class="detail-text">
                                        <span class="detail-text-label">Event and Campaign:</span>
                                        <span class="detail-text-value" style="text-align: justify;">${enquiryDetail.eventAndCampaignName || 0}</span>
                                    </div>` : '' }
                                ${enquiryDetail.eventAndCampaignNotes && enquiryDetail.eventAndCampaignNotes !== '' ?
                                    `<div class="detail-text">
                                        <span class="detail-text-label">Event and Campaign Notes:</span>
                                        <span class="detail-text-value" style="text-align: justify;">${enquiryDetail.eventAndCampaignNotes || 0}</span>
                                    </div>` : '' }
                            </div>
                        </div>
                    </section>
                    ${getOrganizationDetails(enquiryDetail)}
                    ${getEnquiryValue(enquiryDetail)}
                    ${getClientDetails(enquiryDetail)}
                    ${getOtherPartyDetails(enquiryDetail)}
                    ${getResponseTextDetails(enquiryDetail)}
                    ${getDocumentDetails(enquiryDetail, '')}
                    ${getCustomFieldDetails(enquiryDetail, currency, customFieldByHierarchy)}
                    ${getDigitalTrackingFields(enquiryDetail)}
                    <section class="section section-history">
                        <h3 class="section-title">History</h3>
                        <div class="section-history-content">
                            ${getHistoryContent(enquiryDetail, currency, timezone, leadDetailsHistory)}
                        </div>
                    </section>
                </main>
            </body>
        </html>
    `;

    // return <>{htmlElememnt}</>;
    return htmlElememnt;
};

const getHistoryContent = (enquiryDetail: EnquiryDetail, currency: string, timezone: TimezoneOption[], leadDetailsHistory: TaskSummaryList) => {
    let historyContent = '';

    const taskList = JSON.parse(JSON.stringify(leadDetailsHistory.tasks));

    const taskCreateClient = taskList.find((task: TaskDetails) => {
        return (task.taskTypeDescription.toLowerCase() === 'create client' || task.taskTypeDescription.toLowerCase() === 'create matter') 
            && task.taskStatusDescription.toLowerCase() === 'not started';
    });

    let newTasks = taskList;

    if (taskCreateClient) {
        const index = taskList.indexOf(taskCreateClient);
        newTasks.splice(index, 1);
    }

    if (taskCreateClient) {
        historyContent = historyContent + getHistoryActivity(taskCreateClient, timezone, currency, 0);
    }

    newTasks.map((task: TaskDetails, index: number) => 
        historyContent = historyContent + getHistoryActivity(task, timezone, currency, index)
    );

    let createHistory = getCreatedContent(enquiryDetail);

    return historyContent + createHistory;
};

const getCreatedContent = (enquiryDetail: EnquiryDetail) => {
    let areaOfLawTitle = enquiryDetail.areaOfLaw 
        ? enquiryDetail.source 
            ? enquiryDetail.areaOfLaw + ' (' + enquiryDetail.source.trim() + ')'
            : enquiryDetail.areaOfLaw
        : '';

    return `
    <div class="history">
        <div class="history-header">
            ${enquiryDetail.createdDate ? (
                `<div class="history-date">${formatDate(enquiryDetail.createdDate, 'Do MMM YYYY hh:mm a')}</div>`
            ) : '' }
            <div class="history-title">Created</div>            
        </div>
        <div class="history-content">
            <ul class="lists">
                ${areaOfLawTitle 
                    ? 
                    (
                        `<li class="">${areaOfLawTitle}</li>`
                    )
                    : ''
                }
                ${enquiryDetail.natureOfEnquiry 
                    ? 
                    (
                        `<li class="">${enquiryDetail.natureOfEnquiry}</li>`
                    )
                    : ''
                }
                ${enquiryDetail.createdByName 
                    ?
                    (
                        `<li class="history-content-createdby">Created by: ${enquiryDetail.createdByName}</li>`
                    )
                    : ''
                }
            </ul>
        </div>
    </div>
    `;
};

const getDescription = (task: TaskDetails) => {
    let actionedDescription = task.actionedDescription && task.actionedDescription.length > 0 
        ? task.actionedDescription 
        : undefined;

    if (task.taskTypeDescription.toLowerCase() === 'enquiry follow up') {
        if (task.followUpAction && task.followUpAction.toLowerCase() === 're-open enquiry') {
            return actionedDescription ? actionedDescription : 'Re-Opened';
        } else if (task.completedDate) {
            return actionedDescription ? actionedDescription : task.followUpAction;
        } else if (task.dueDate) {
            return actionedDescription ? actionedDescription : 'Follow Up Due';
        } else {
            return actionedDescription ? actionedDescription : task.followUpAction;
        }
    } else {
        const enquiryText = task.isKeyOpportunity ? MatterTerminology.KeyOpportunity : MatterTerminology.Enquiry;

        if (task.enquiryLinkedMatter) {
            return 'Link Matter';
        }

        const taskTypeDescription = task.taskTypeDescription.replace('Enquiry', enquiryText);
        return taskTypeDescription;
    }
};

const getHistoryDate = (task: TaskDetails) => {
    if (task.taskTypeInternalDescription === 'BookMeeting' && task.updatedDate) {
        return formatDate(task.updatedDate, 'Do MMM YYYY hh:mm a');
    }

    if (task.updatedDate) {
        return formatDate(task.updatedDate, 'Do MMM YYYY hh:mm a');
    } else if (task.dueDateUTC) {
        return formatDate(convertToLocalTimeZone(task.dueDateUTC.toString()), 'Do MMM YYYY hh:mm a');
    } else {
        // this is Due before not current date
        return formatDate(new Date(), 'Do MMM YYYY hh:mm a');
    }

};

const getOrganizationDetails = (ed: EnquiryDetail) => {
    return (`
        ${(ed.organisation || ed.positionAtOrganisation) ? 
        `<section class="section section-enquiry">
            <div class="section-content details">
                <div class="detail detail-1">
                    <h3 class="subsection-title">Organization</h3>
                    ${ed.organisation && ed.organisation !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Organization Name:</span>
                            <span class="detail-text-value">${ed.organisation}</span>
                        </div>` : ''}
                    ${ed.positionAtOrganisation && ed.positionAtOrganisation !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Position:</span>
                            <span class="detail-text-value">${ed.positionAtOrganisation}</span>
                        </div>` : ''}
                </div>
            </div> 
        </section>` : ''}
    `);
};

const getResponseTextDetails = (ed: EnquiryDetail) => {
    return (`
        ${(ed.responseText) ? 
        `<section class="section section-enquiry">
                <div class="section-content details">
                    <div class="detail detail-1">
                        <h3 class="subsection-title">Detailed Enquiry Notes</h3>
                        ${ed.responseText && ed.responseText !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Notes:</span>
                            <span class="detail-text-value">${ed.responseText}</span>
                        </div>` : '' }
                    </div>
                </div> 
            </section>` : ''}
    `);
};

const getEnquiryValue = (enquiryDetail: EnquiryDetail) => {
    return (`
        <section class="section section-enquiry">
            <div class="section-content details">
            ${(enquiryDetail.estimatedFees || enquiryDetail.enquiryQuality) ? 
                `<div class="detail detail-1">
                    <h3 class="subsection-title">Enquiry Value</h3>
                    <div class="detail-text">
                        <span class="detail-text-label">Estimated Fees:</span>
                        <span class="detail-text-value">${enquiryDetail.estimatedFees || 0}</span>
                    </div>
                    <div class="detail-text">
                        <span class="detail-text-label">Includes Taxes:</span>
                        <span class="detail-text-value">
                            <input type="checkbox" checked="${enquiryDetail.isEstimatedFeesIncludesTax}" />
                        </span>
                    </div>
                    <div class="detail-text">
                        <span class="detail-text-label">Enquiry Quality:</span>
                        <span class="detail-text-value">${enquiryDetail.enquiryQuality || 0}</span>
                    </div>
                </div>` : ''}
            ${(enquiryDetail.estimatedDisbursements || enquiryDetail.likelihoodToProceed) ? 
                `<div class="detail detail-3">
                    <h3 class="subsection-title">&nbsp;</h3>
                    <div class="detail-text">
                        <span class="detail-text-label">Estimated Disbs:</span>
                        <span class="detail-text-value">${enquiryDetail.estimatedDisbursements || 0}</span>
                    </div>
                    <div class="detail-text">
                        <span class="detail-text-label">Includes Taxes:</span>
                        <span class="detail-text-value">
                            <input type="checkbox" checked="${enquiryDetail.isEstimatedDisbursementsIncludesTax}" />
                        </span>
                    </div>
                    <div class="detail-text">
                        <span class="detail-text-label">Likelihood to Proceed:</span>
                        <span class="detail-text-value">${enquiryDetail.likelihoodToProceed || 0}</span>
                    </div>
                </div>` : ''}    
            </div>
        </section>
    `);
};

const getClientDetails = (ed: EnquiryDetail) => {
    return (`
        <div>
        <section class="section section-enquiry">\
            <div class="section-content details">
                <div class="detail detail-1">
                    <h3 class="subsection-title">Client Details</h3>
                    ${(ed.clientFirstName && ed.clientLastName) && (ed.clientFirstName !== '' && ed.clientLastName !== '') ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Client Name:</span>
                            <span class="detail-text-value">${setTitle(ed.clientTitle)}${ed.clientFirstName}&nbsp;${ed.clientLastName}</span>
                        </div>` : '' }
                    ${ed.clientRelationshipToEnquirer && ed.clientRelationshipToEnquirer !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Relationship to the Enquirer:</span>
                            <span class="detail-text-value">${ed.clientRelationshipToEnquirer}</span>
                        </div>` : '' }
                    ${ed.dOB ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Birthday:</span>
                            <span class="detail-text-value">${formatDate(ed.dOB, 'DD-MMM-YYYY')}</span>
                        </div>` : '' }
                    ${ed.gender ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Gender of Client:</span>
                            <span class="detail-text-value">${ed.gender}</span>
                        </div>` : '' }
                    ${ed.clientEmail ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Email:</span>
                            <span class="detail-text-value">${ed.clientEmail}</span>
                        </div>` : ''}
                    ${ed.clientPhone ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Telephone:</span>
                            <span class="detail-text-value">${ed.clientPhone}</span>
                        </div>` : ''}
                    ${ed.clientMobile ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Mobile:</span>
                            <span class="detail-text-value">${ed.clientMobile}</span>
                        </div>` : ''}
                    <div class="detail-text checkbox-group">
                    ${ed.isMarketingConsent ?
                        `<div>
                            <input type="checkbox" ${ed.isMarketingConsent && 'checked="true"'} />
                            <label>Marketing Consent</label>
                        </div>` : '' }
                    ${ed.isTermsAndConditionsApproved ?
                            `<div>
                                <input type="checkbox" ${ed.isTermsAndConditionsApproved && 'checked="true"'} />
                                <label>T&C's Approved</label>
                            </div>` : '' }
                    ${ed.isPrivacyStatementProvided ?
                        `<div>
                            <input type="checkbox" ${ed.isPrivacyStatementProvided && 'checked="true"'} />
                            <label>Privacy Statement Provided</label>
                        </div>` : '' }
                    </div>
                    <div class="detail-text checkbox-group">
                    ${ed.isConflictCheckPerformed ?
                        `<div>
                            <input type="checkbox" ${ed.isConflictCheckPerformed && 'checked="true"'} />
                            <label>Conflict Check Performed</label>
                        </div>` : '' }
                    ${ed.isNoConflict ?
                        `<div>
                            <input type="checkbox" ${ed.isNoConflict && 'checked="true"'} />
                            <label>No Conflict</label>
                        </div>` : '' }
                    ${ed.isIdentityCheckPerformed ?
                        `<div>
                            <input type="checkbox" ${ed.isIdentityCheckPerformed && 'checked="true"'} />
                            <label>Identity Check Performed</label>
                        </div>` : '' }
                    </div>
                </div>
            </div>
            <div class="section-content details">
            ${(ed.addressName || ed.floor || ed.number || ed.street || ed.clientAddressStreet2 || ed.suburb || ed.state || ed.postcode || ed.country) ?
                `<div class="detail detail-3">
                    <h3 class="subsection-subtitle">Main Address</h3>
                    ${ed.addressName && ed.addressName !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Building Name:</span>
                            <span class="detail-text-value">${ed.addressName}</span>
                        </div>` : '' }
                    ${ed.floor && ed.floor !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Unit Level:</span>
                            <span class="detail-text-value">${ed.floor}</span>
                        </div>` : '' }
                    ${ed.number && ed.number !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Number:</span>
                            <span class="detail-text-value">${ed.number}</span>
                        </div>` : '' }
                    ${ed.street && ed.street !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Street 1:</span>
                            <span class="detail-text-value">${ed.street}</span>
                        </div>` : '' }
                    ${ed.clientAddressStreet2 && ed.clientAddressStreet2 !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Street 2:</span>
                            <span class="detail-text-value">${ed.clientAddressStreet2}</span>
                        </div>` : '' }
                    ${ed.suburb && ed.suburb !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">City/Suburb:</span>
                            <span class="detail-text-value">${ed.suburb}</span>
                        </div>` : '' }
                    ${ed.state && ed.state !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">State:</span>
                            <span class="detail-text-value">${ed.state}</span>
                        </div>` : '' }
                    ${ed.postcode && ed.postcode !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Postal Code:</span>
                            <span class="detail-text-value">${ed.postcode}</span>
                        </div>` : '' }
                    ${ed.country && ed.country !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Country:</span>
                            <span class="detail-text-value">${ed.country}</span>
                        </div>` : '' }
                </div>` : '' }
                ${(ed.pOBox || ed.postalSuburb || ed.postalState || ed.postalPostCode || ed.postalCountry) ?
                `<div class="detail detail-3">
                    <h3 class="subsection-subtitle">Postal Address</h3>
                    ${ed.pOBox && ed.pOBox !== ''  ? 
                        ` <div class="detail-text">
                            <span class="detail-text-label">PO Box Type:</span>
                            <span class="detail-text-value">${ed.pOBox}</span>
                        </div>` : ''}
                    ${ed.postalSuburb && ed.postalSuburb !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Suburb:</span>
                            <span class="detail-text-value">${ed.postalSuburb}</span>
                        </div>` : '' }
                    ${ed.postalState && ed.postalState !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">State:</span>
                            <span class="detail-text-value">${ed.postalState}</span>
                        </div>` : '' }
                    ${ed.postalPostCode && ed.postalPostCode !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Postal Code:</span>
                            <span class="detail-text-value">${ed.postalPostCode}</span>
                        </div>` : '' }
                    ${ed.postalCountry && ed.postalCountry !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Country:</span>
                            <span class="detail-text-value">${ed.postalCountry}</span>
                        </div>` : '' }
                </div>` : '' }
            </div>
        </section>
        <section class="section section-enquiry">\
            <div class="section-content details">
                <div class="detail detail-1">
                    <h3 class="subsection-title">Client 2 Details</h3>
                    ${(ed.secondClientFirstName && ed.secondClientLastName) && (ed.secondClientFirstName !== '' && ed.secondClientLastName !== '') ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Client Name:</span>
                            <span class="detail-text-value">${setTitle(ed.secondClientTitle)}${ed.secondClientFirstName}&nbsp;${ed.secondClientLastName}</span>
                        </div>` : '' }
                    ${ed.secondClientRelationshipToEnquirer && ed.secondClientRelationshipToEnquirer !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Relationship to the Enquirer:</span>
                            <span class="detail-text-value">${ed.secondClientRelationshipToEnquirer}</span>
                        </div>` : '' }
                    ${ed.secondClientDOB ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Birthday:</span>
                            <span class="detail-text-value">${formatDate(ed.secondClientDOB, 'DD-MMM-YYYY')}</span>
                        </div>` : '' }
                    ${ed.secondClientGender ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Gender of Client:</span>
                            <span class="detail-text-value">${ed.secondClientGender}</span>
                        </div>` : '' }
                    ${ed.secondClientEmail ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Email:</span>
                            <span class="detail-text-value">${ed.secondClientEmail}</span>
                        </div>` : ''}
                    ${ed.secondClientPhone ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Telephone:</span>
                            <span class="detail-text-value">${ed.secondClientPhone}</span>
                        </div>` : ''}
                    ${ed.secondClientMobile ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Mobile:</span>
                            <span class="detail-text-value">${ed.secondClientMobile}</span>
                        </div>` : ''}
                    <div class="detail-text checkbox-group">
                    ${ed.isMarketingConsent ?
                        `<div>
                            <input type="checkbox" ${ed.isMarketingConsent && 'checked="true"'} />
                            <label>Marketing Consent</label>
                        </div>` : '' }
                    ${ed.secondClientIsTermsAndConditionsApproved ?
                        `<div>
                            <input type="checkbox" ${ed.secondClientIsTermsAndConditionsApproved && 'checked="true"'} />
                            <label>T&C's Approved</label>
                        </div>` : '' }
                    ${ed.secondClientIsPrivacyStatementProvided ?
                        `<div>
                            <input type="checkbox" ${ed.secondClientIsPrivacyStatementProvided && 'checked="true"'} />
                            <label>Privacy Statement Provided</label>
                        </div>` : '' }
                    </div>
                    <div class="detail-text checkbox-group">
                    ${ed.secondClientIsConflictCheckPerformed ?
                        `<div>
                            <input type="checkbox" ${ed.secondClientIsConflictCheckPerformed && 'checked="true"'} />
                            <label>Conflict Check Performed</label>
                        </div>` : '' }
                    ${ed.secondClientIsNoConflict ?
                        `<div>
                            <input type="checkbox" ${ed.secondClientIsNoConflict && 'checked="true"'} />
                            <label>No Conflict</label>
                        </div>` : '' }
                    ${ed.secondClientIsIdentityCheckPerformed ?
                        `<div>
                            <input type="checkbox" ${ed.secondClientIsIdentityCheckPerformed && 'checked="true"'} />
                            <label>Identity Check Performed</label>
                        </div>` : '' }
                    </div>
                </div>
            </div>
            <div class="section-content details">
            ${(ed.secondClientAddressName || ed.secondClientFloor || ed.secondClientNumber || ed.secondClientStreet1 || ed.secondClientStreet2 || ed.secondClientSuburb || ed.secondClientState || ed.secondClientPostCode || ed.secondClientCountry) ?
                `<div class="detail detail-3">
                    <h3 class="subsection-subtitle">Main Address</h3>
                    ${ed.secondClientAddressName && ed.secondClientAddressName !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Building Name:</span>
                            <span class="detail-text-value">${ed.secondClientAddressName}</span>
                        </div>` : '' }
                    ${ed.secondClientFloor && ed.secondClientFloor !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Unit Level:</span>
                            <span class="detail-text-value">${ed.secondClientFloor}</span>
                        </div>` : '' }
                    ${ed.secondClientNumber && ed.secondClientNumber !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Number:</span>
                            <span class="detail-text-value">${ed.secondClientNumber}</span>
                        </div>` : '' }
                    ${ed.secondClientStreet1 && ed.secondClientStreet1 !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Street 1:</span>
                            <span class="detail-text-value">${ed.secondClientStreet1}</span>
                        </div>` : '' }
                    ${ed.secondClientStreet2 && ed.secondClientStreet2 !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Street 2:</span>
                            <span class="detail-text-value">${ed.secondClientStreet2}</span>
                        </div>` : '' }
                    ${ed.secondClientSuburb && ed.secondClientSuburb !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">City/Suburb:</span>
                            <span class="detail-text-value">${ed.secondClientSuburb}</span>
                        </div>` : '' }
                    ${ed.secondClientState && ed.secondClientState !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">State:</span>
                            <span class="detail-text-value">${ed.secondClientState}</span>
                        </div>` : '' }
                    ${ed.secondClientPostCode && ed.secondClientPostCode !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Postal Code:</span>
                            <span class="detail-text-value">${ed.secondClientPostCode}</span>
                        </div>` : '' }
                    ${ed.secondClientCountry && ed.secondClientCountry !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Country:</span>
                            <span class="detail-text-value">${ed.secondClientCountry}</span>
                        </div>` : '' }
                </div>` : '' }
                ${(ed.secondClientPOBox || ed.secondClientPostalSuburb || ed.secondClientPostalState || ed.secondClientPostalPostCode || ed.secondClientPostalCountry) ?
                `<div class="detail detail-3">
                    <h3 class="subsection-subtitle">Postal Address</h3>
                    ${ed.secondClientPOBoxType && ed.secondClientPOBoxType !== ''  ? 
                        ` <div class="detail-text">
                            <span class="detail-text-label">PO Box Type:</span>
                            <span class="detail-text-value">${ed.secondClientPOBoxType}</span>
                        </div>` : ''}
                    ${ed.secondClientPostalSuburb && ed.secondClientPostalSuburb !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Suburb:</span>
                            <span class="detail-text-value">${ed.secondClientPostalSuburb}</span>
                        </div>` : '' }
                    ${ed.secondClientPostalState && ed.secondClientPostalState !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">State:</span>
                            <span class="detail-text-value">${ed.secondClientPostalState}</span>
                        </div>` : '' }
                    ${ed.secondClientPostalPostCode && ed.secondClientPostalPostCode !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Postal Code:</span>
                            <span class="detail-text-value">${ed.secondClientPostalPostCode}</span>
                        </div>` : '' }
                    ${ed.secondClientPostalCountry && ed.secondClientPostalCountry !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Country:</span>
                            <span class="detail-text-value">${ed.secondClientPostalCountry}</span>
                        </div>` : '' }
                </div>` : '' }
            </div>
        </section>
        <section class="section section-enquiry">
            <div class="section-content details">
                <div class="detail detail-1">
                    <h3 class="subsection-title">Risk Details</h3>
                    <div class="detail-text checkbox-group">
                        <div>
                            <input type="checkbox" ${ed.isRiskAssessed && 'checked="true"'} />
                            <label>Risk Assessed</label>
                        </div>
                    </div>
                    ${ed.isRiskAssessed ? `
                        <div>
                            <span class="detail-text-label">Risk Level:</span>
                            <span class="detail-text-value" style="text-align: justify;">${ed.riskLevel || ''}</span>
                        </div>
                        <div>
                            <span class="detail-text-label">Risk Notes:</span>
                            <span class="detail-text-value" style="text-align: justify;">${ed.riskNotes || ''}</span>
                        </div>
                    ` : ''}
                </div>
            </div>
        </section>
        </div>
    `);
};

const getOtherPartyDetails = (ed: EnquiryDetail) => {
    if ((!ed.otherPartyFirstName && !ed.otherPartyLastName) || (ed.otherPartyFirstName === '' && ed.otherPartyLastName === '')) {
        return '';
    }

    return (`
        <section class="section section-enquiry">
            <div class="section-content details">
                <div class="detail detail-1">
                    <h3 class="subsection-title">Other Party</h3>
                        ${(ed.otherPartyFirstName && ed.otherPartyLastName) && (ed.otherPartyFirstName !== '' && ed.otherPartyLastName !== '') ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Name:</span>
                            <span class="detail-text-value">${setTitle(ed.otherPartyTitle)}${ed.otherPartyFirstName}&nbsp;${ed.otherPartyLastName}</span>
                        </div>` : '' }
                    ${ed.otherPartyEmail && ed.otherPartyEmail !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Email:</span>
                            <span class="detail-text-value"> ${ed.otherPartyEmail}</span>
                        </div>` : '' }
                    ${ed.otherPartyPhone && ed.otherPartyPhone !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Telephone:</span>
                            <span class="detail-text-value">${ed.otherPartyPhone}</span>
                        </div>` : '' }
                    ${ed.otherPartyDOB ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Birthday:</span>
                            <span class="detail-text-value">${ed.otherPartyDOB}</span>
                        </div>` : '' }
                    ${ed.otherPartyMobile && ed.otherPartyMobile !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Mobile:</span>
                            <span class="detail-text-value">${ed.otherPartyMobile}</span>
                        </div>` : '' }
                    ${ed.otherPartyNotes && ed.otherPartyNotes !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Notes:</span>
                            <span class="detail-text-value">${ed.otherPartyNotes}.</span>
                        </div>` : '' }
                </div>
            </div>
            <div class="section-content details">
            ${(ed.otherPartyAddressName || ed.otherPartyFloor || ed.otherPartyNumber || ed.otherPartyStreet || ed.otherPartyStreet2 || ed.otherPartySuburb || ed.otherPartyState || ed.otherPartyPostcode || ed.otherPartyCountry) ?
                `<div class="detail detail-3">
                    <h3 class="subsection-subtitle">Main Address</h3>
                    ${ed.otherPartyAddressName && ed.otherPartyAddressName !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Building Name:</span>
                            <span class="detail-text-value">${ed.otherPartyAddressName}</span>
                        </div>` : '' }
                    ${ed.otherPartyFloor && ed.otherPartyFloor !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Unit Level:</span>
                            <span class="detail-text-value">${ed.otherPartyFloor}</span>
                        </div>` : '' }
                    ${ed.otherPartyNumber && ed.otherPartyNumber !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Number:</span>
                            <span class="detail-text-value">${ed.otherPartyNumber}</span>
                        </div>` : '' }
                    ${ed.otherPartyStreet ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Street 1:</span>
                            <span class="detail-text-value">${ed.otherPartyStreet}</span>
                        </div>` : '' }
                    ${ed.otherPartyStreet2 && ed.otherPartyStreet2 !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Street 2:</span>
                            <span class="detail-text-value">${ed.otherPartyStreet2}</span>
                        </div>` : '' }
                    ${ed.otherPartySuburb && ed.otherPartySuburb !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">City/Suburb:</span>
                            <span class="detail-text-value">${ed.otherPartySuburb}</span>
                        </div>` : '' }
                    ${ed.otherPartyState && ed.otherPartyState !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">State:</span>
                            <span class="detail-text-value">${ed.otherPartyState}</span>
                        </div>` : '' }
                    ${ed.otherPartyPostalPostCode && ed.otherPartyPostcode !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Postal Code:</span>
                            <span class="detail-text-value">${ed.otherPartyPostalPostCode}</span>
                        </div>` : '' }
                    ${ed.otherPartyCountry && ed.otherPartyCountry !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Country:</span>
                            <span class="detail-text-value">${ed.otherPartyCountry}</span>
                        </div>` : '' }
                </div>` : '' }
                ${(ed.otherPartyPOBoxType || ed.otherPartyPOBox || ed.otherPartyPostalSuburb || ed.otherPartyPostalPostCode || ed.otherPartyPostalCountry) ?
                `<div class="detail detail-3">
                    <h3 class="subsection-subtitle">Postal Address</h3>
                    ${ed.otherPartyPOBoxType && ed.otherPartyPOBoxType !== '' ?
                    `<div class="detail-text">
                        <span class="detail-text-label">PO Box Type:</span>
                        <span class="detail-text-value">${ed.otherPartyPOBoxType}</span>
                    </div>` : '' }
                    ${ed.otherPartyPOBox && ed.otherPartyPOBox !== '' ?
                    `<div class="detail-text">
                        <span class="detail-text-label">Number:</span>
                        <span class="detail-text-value">${ed.otherPartyPOBox}</span>
                    </div>` : '' }
                    ${ed.otherPartyPostalSuburb && ed.otherPartyPostalSuburb !== '' ?
                    `<div class="detail-text">
                        <span class="detail-text-label">Suburb:</span>
                        <span class="detail-text-value">${ed.otherPartyPostalSuburb}</span>
                    </div>` : '' }
                    ${ed.otherPartyPostalState && ed.otherPartyPostalState !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">State:</span>
                            <span class="detail-text-value">${ed.otherPartyPostalState}</span>
                        </div>` : '' }
                    ${ed.otherPartyPostalPostCode && ed.otherPartyPostalPostCode !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Postal Code:</span>
                            <span class="detail-text-value">${ed.otherPartyPostalPostCode}</span>
                        </div>` : '' }
                    ${ed.otherPartyPostalCountry && ed.otherPartyPostalCountry !== '' ?
                        `<div class="detail-text">
                            <span class="detail-text-label">Country:</span>
                            <span class="detail-text-value">${ed.otherPartyPostalCountry}</span>
                        </div>` : '' }
                </div>` : '' }
            </div>
        </section>
    `);
};

const getDocumentDetails = (ed: EnquiryDetail, tags: string) => {
    if (ed.files && ed.files.length > 0) {
        ed.files.forEach(e => {
            tags += `<li>${e.name}</li>`;
        });

        if (!tags || tags === '') {
            return '';
        }

        return (`
            <section class="section section-enquiry">
                <div class="section-content details">
                    <div class="detail detail-1">
                        <h3 class="subsection-title">Documents</h3>
                        <div class="detail-text" style="padding-left: 10px;">
                            <ul>
                                ${tags}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        `);
    } else {
        return '';
    }
};

function getAnswer(matchingDetail : EnquiryDetailCustomField | undefined, currency: string) {
    let answer = null;

    if (typeof matchingDetail?.boolValue === 'boolean') {
        answer = matchingDetail.boolValue ? 'Yes' : 'No';
    } 
    else if (matchingDetail?.currencyValue !== null) {
        answer =  currency + formatNumber(matchingDetail?.currencyValue ?? 0);
    } 
    else if (matchingDetail?.dateTimeValue !== null) {
        answer = formatDate(matchingDetail?.dateTimeValue, 'DD MMM YYYY hh:mm a')
    } 
    else if (matchingDetail?.dateValue !== null) {
        answer = formatDate(matchingDetail?.dateValue, 'DD MMM YYYY')
    } 
    else if (matchingDetail?.integerValue !== null) {
        answer = matchingDetail?.integerValue
    } 
    else if (matchingDetail?.multiLineTextValue !== null) {
        answer = matchingDetail?.multiLineTextValue;
    } 
    else if (matchingDetail?.numberValue !== null) {
        answer =  matchingDetail?.numberValue
    } 
    else if (matchingDetail?.textValue !== null) {
        answer = matchingDetail?.textValue;
    }    
    else if (matchingDetail?.lookupListName !== null) {
        answer = matchingDetail?.lookupListName;
    }

    return answer;
};

const getCustomFieldDetails = (enquiry: EnquiryDetail, currency: string, customFieldByHierarchy?: CustomFieldByHierarchy[]) => {
    let customFieldItem = '';
    
    if (customFieldByHierarchy && customFieldByHierarchy?.length > 0) {
        customFieldByHierarchy?.forEach(group => {
            const filteredFields = group.customField.filter(filteredDetail => {
                const matchingDetail = enquiry.customFields.find(enquiry => enquiry.customFieldGuid === filteredDetail.guid);
                return matchingDetail;
            });

            if (filteredFields.length > 0) {
                customFieldItem += 
                `
                    <h3 class="subsection-title">${group.customFieldGroupName}</h3>
                    <ul class="custom-field-item">
                        ${filteredFields.map(customFieldAnswer => {
                            if (customFieldAnswer?.isActive) {
                                const matchingDetail = enquiry.customFields.find(detail => detail.customFieldGuid === customFieldAnswer.guid);
                                const answer = getAnswer(matchingDetail, currency)
                                return `<li>${customFieldAnswer.description} : ${answer}</li>`;
                            }
                            return '';
                        }).join('')}
                    </ul>
                `;
            }
        });
    }

    return (`
        ${customFieldItem}
    `);
};

const getDigitalTrackingFields = (ed: EnquiryDetail) => {
    return (`
        ${(ed.organisation || ed.positionAtOrganisation) ? 
        `<section class="section section-enquiry">
            <div class="section-content details">
                <div class="detail detail-1">
                    <h3 class="subsection-title">Digital Tracking</h3>
                    ${ed.clickThroughUrl && ed.clickThroughUrl !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">Click Through Url:</span>
                            <span class="detail-text-value">${ed.clickThroughUrl}</span>
                        </div>` : ''}
                    ${ed.utmSource && ed.utmSource !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">UTM Source:</span>
                            <span class="detail-text-value">${ed.utmSource}</span>
                        </div>` : ''}
                    ${ed.utmMedium && ed.utmMedium !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">UTM Medium:</span>
                            <span class="detail-text-value">${ed.utmMedium}</span>
                        </div>` : ''}
                    ${ed.utmCampaign && ed.utmCampaign !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">UTM Campaign:</span>
                            <span class="detail-text-value">${ed.utmCampaign}</span>
                        </div>` : ''}
                    ${ed.utmContent && ed.utmContent !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">UTM utmContent:</span>
                            <span class="detail-text-value">${ed.utmSource}</span>
                        </div>` : ''}
                    ${ed.utmKeyword && ed.utmKeyword !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">UTM Keyword:</span>
                            <span class="detail-text-value">${ed.utmKeyword}</span>
                        </div>` : ''}
                    ${ed.googleClickIdentifier && ed.googleClickIdentifier !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">GCLID:</span>
                            <span class="detail-text-value">${ed.googleClickIdentifier}</span>
                        </div>` : ''}
                    ${ed.facebookClickIdentifier && ed.facebookClickIdentifier !== '' ? 
                        `<div class="detail-text">
                            <span class="detail-text-label">FBCLID:</span>
                            <span class="detail-text-value">${ed.facebookClickIdentifier}</span>
                        </div>` : ''}
                </div>
            </div> 
        </section>` : ''}
    `);
};

const getHistoryDetailContent = (task: TaskDetails, timezone: TimezoneOption[], currency: string) => {
    let uiUtcOffset = new Date().getTimezoneOffset() * -1;

    let uiTimeZoneDescription = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timezone) {
        let uiTimeZone = timezone.find((item: TimezoneOption) => {
            return item.baseUtcOffset === uiUtcOffset;
        });

        if (uiTimeZone) {
            uiTimeZoneDescription = uiTimeZone.timezoneValue;
        }
    }

    if (task.taskTypeDescription.toLowerCase() === 'assign enquiry' && task.assignedToGuidID) {
        return (
            `
            <ul class="lists">
                <li>Assigned to <span>${task.assignedToName}</span> by <span>${task.assignedByName}</span></li>
                ${task.followUpNotes
                    ? (
                        `<li>
                            ${convertMentionInCommentToHtml(task.followUpNotes)}
                        </li>`
                    ) 
                    : ''
                }  
            </ul>
            `
        );
    } else if (task.enquiryLinkedMatter || task.taskTypeDescription.toLowerCase() === 'link matter') {
        return (
            `
            <ul class="lists">
                <li>${task.assignedByName}</span></li>
                ${task.enquiryLinkedMatter
                    ? (
                        `<li>
                            ${task.linkedMatterClient ? task.linkedMatterClient : '' } - ${task.linkedMatterTitle } (${task.enquiryLinkedMatter})
                        </li>`
                    )
                    :
                    `Matter synching is in progress. Please check again after a short while.`
                }  
            </ul>
            `
        );
    } else if (task.taskTypeInternalDescription === 'BookMeeting') {
        return (
            `
            <ul class="lists">
                ${task.isAssignedToTeam && task.assignedToName ? (
                    `<li>
                        ${`Meeting scheduled at ${task.firmOffice} ${task.bookMeetingStartDateUTC ? `on ${moment(convertToLocalTimeZone(task.bookMeetingStartDateUTC.toString())).format('DD MMM YYYY hh:mm a')}` : ''} (${task.timezone ? uiTimeZoneDescription : ''})`}
                    </li>`
                ) : ''}
                ${!task.isAssignedToTeam && task.staff
                    ? (
                        `<li>
                            Meeting with ${task.staff} 
                            ${(task.firmOffice !== null ? (' at ' + task.firmOffice + '' ) : '' )}
                            ${(task.bookMeetingStartDateUTC !== null
                                ? (' on ' + moment(convertToLocalTimeZone(task.bookMeetingStartDateUTC.toString())).format('DD MMM YYYY hh:mm a')  ) 
                                : '' )}
                                ${(task.timezone !== null ? ' (' + uiTimeZoneDescription + ')' : '')}
                        </li>`
                    )
                    : ''
                }
                ${task.meetingNotes 
                    ? 
                    (
                        `<li>Meeting Notes: ${task.meetingNotes}</li>`
                    )
                    : ''
                }
                ${task.sendConfirmation && !task.isAssignedToTeam
                    ?
                    (
                        `<li>Confirmation Sent: ${task.sendConfirmation === true ? 'Yes' : 'No'}</li>`
                    )
                    : ''
                }
                ${task.feeApplicable 
                    ?
                    (
                        `<li>Fee Applicable: ${currency}${formatNumber(task.feeApplicable)}</li>`
                    )
                    : ''
                }
                ${task.assignedToName && task.isAssignedToTeam 
                    ?
                    (
                        `<li>Assigned To: ${task.assignedToName}</li>`
                    )
                    : ''
                }
                ${task.followUpNotes
                    ? 
                    (
                        `<li>
                            ${convertMentionInCommentToHtml(task.followUpNotes, 'Follow Up Notes')}
                        </li>`
                    )
                    : ''
                }  
            </ul>
            `
        );
    } else if (task.taskTypeInternalDescription === 'MeetingTask' && (task.followUpNotes || task.outcomeNotes)) {
        return (
            `
            <ul class="lists">
                <li>${task.assignedByName}</span></li>
                ${task.followUpNotes !== null && task.followUpNotes
                    ? (                        
                        `<li>
                            ${convertMentionInCommentToHtml(task.followUpNotes)}
                        </li>`
                    )
                    : ''
                }  
                ${task.outcomeNotes !== null && task.outcomeNotes
                    ? 
                    (
                        `<li>Previous Notes: ${task.outcomeNotes} 
                            ${(task.createdDate ? ' (' + moment(task.createdDate).format('DD MMM YYYY') + ') ' : '')}
                        </li>`
                    )
                    : ''
                }              
            </ul>
            `
        );
    } else if (task.systemAction === 'CreateEmail') {        
        return (
            `
            <ul class="lists">
                <li>${task.assignedByName}</span></li>
                ${task.emailTemplateName
                    ? 
                    (
                        `<li>Template Name: ${task.emailTemplateName}</li>`
                    )
                    : ''
                }  
                ${task.emailSubject
                    ?
                    (
                        `<li>Subject: ${task.emailSubject}</li>`
                    )
                    : ''
                }  
                ${task.fromEmailAddress
                    ? 
                    (
                        `<li>From: ${task.fromEmailAddress}</li>`
                    )
                    : ''
                }  
                ${task.toEmailAddresses
                    ? 
                    (
                        `<li>To: ${task.toEmailAddresses}</li>`
                    )
                    : ''
                }  
                ${task.ccEmailAddresses
                    ? 
                    (
                        `<li>Cc: ${task.ccEmailAddresses}</li>`
                    )
                    : ''
                }  
                ${task.assignedByName
                    ? 
                    (
                        `<li>Sent by: ${task.assignedByName}</li>`
                    )
                    : ''
                }
                ${task.outcomeNotes
                    ? 
                    (
                        `<li>Previous Notes: '${task.outcomeNotes} 
                            ${(task.createdDate !== null ? ' (' + moment(task.createdDate).format('DD MMM YYYY') + ') ' : '')}
                        </li>`
                    )
                    : ''
                }
            </ul>
            `
        );
    } else if ((task.dueDate && task.completedDate) && getDescription(task) !== 'Follow Up Due') {
        return (
            `
            <ul class="lists">
                <li>${task.assignedByName}</span></li>
                ${task.enquiryLinkedMatter
                    ? 
                    (
                        `<li>Filenumber: ${task.enquiryLinkedMatter}</li>`
                    )
                    : ''
                }  
                ${task.followUpNotes
                    ? 
                    (
                        `<li>
                            ${convertMentionInCommentToHtml(task.followUpNotes, 'Follow Up Notes')}
                        </li>`
                    )
                    : ''
                }
                ${task.outcomeNotes
                    ? 
                    (
                        `<li>Previous Notes: '${task.outcomeNotes} 
                            ${(task.createdDate !== null ? ' (' + moment(task.createdDate).format('DD MMM YYYY') + ') ' : '')}
                        </li>`
                    )
                    : ''
                }
            </ul>
            `
        );
    } else if (task.followUpNotes) {
        return (
            `
            <ul class="lists">
                <li>${task.assignedByName}</span></li>
                ${task.enquiryLinkedMatter
                    ? 
                    (
                        `<li>Filenumber: ${task.enquiryLinkedMatter}</li>`
                    )
                    : ''
                }  
                ${task.followUpNotes
                    ? 
                    (
                        `<li>
                            ${convertMentionInCommentToHtml(task.followUpNotes)}
                        </li>`
                    )
                    : ''
                }
            </ul>
            `                
        );
    } else if (getDescription(task) === 'Follow Up Due' && task.outcomeNotes) {
        return (
            `
            <ul class="lists">
                <li>${task.assignedByName}</span></li>
                ${task.enquiryLinkedMatter
                    ? 
                    (
                        `<li>Filenumber: ${task.enquiryLinkedMatter}</li>`
                    )
                    : ''
                }  
                ${task.outcomeNotes
                    ? (
                        `<li>Sent by: ${task.outcomeNotes}</li>`
                    )
                    : ''
                }
            </ul>
            `
        );
    } else if (task.assignedByName && task.completedDate) {
        return (
            `
            <ul class="lists">
                <li>${task.assignedByName}</span></li>
            </ul>
            `
        );
    } else {
        return ``;
    }
};

const getHistoryActivity = (task: TaskDetails, timezone: TimezoneOption[], currency: string, index: number) => {
    let historyTitle = getDescription(task);
    let historyTitleDate = getHistoryDate(task);
    let historyDetailsContent = getHistoryDetailContent(task, timezone, currency);

    return `
        <div class="history">
            <div class="history-header">
                <div class="history-date">${historyTitleDate}</div>
                <div class="history-title">${historyTitle}</div>                
            </div>
            <div class="history-content-description">
                ${historyDetailsContent}
            </div>
        </div>        
    `;
};

export const getPdf = (htmlElement: HTMLElement, element: HTMLDivElement) => {
    // console.log('htmlElement', htmlElement);

    // var element = document.createElement('div');
    // element.setAttribute('id', 'historyDiv');
    // element.style.visibility = 'hidden';    
    // element.appendChild(htmlElement); 
    // document.body.appendChild(element);

    // var htmlWidth = element.offsetWidth;
    // var htmlHeight = 1000; // element.offsetHeight;

    // var topLeftMargin = 15;
    // var pdfWidth = 210; // htmlWidth + (topLeftMargin * 2);
    // var pdfHeight = 297;// (pdfWidth * 1.5) + (topLeftMargin * 2);
    // var pdfWidth = (topLeftMargin * 2);
    // var pdfHeight =(topLeftMargin * 2);
    // var canvasImageWidth = 210;
    // var canvasImageHeight = 297;

    // var totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1;

    // console.log('htmlHeight', htmlHeight)
    // console.log('pdfHeight', pdfHeight)
    // console.log('totalPDFPages', totalPDFPages)

    // console.log('element 0', element);

    // tslint:disable-next-line: typedef
    html2canvas(element[0], { allowTaint: true }).then(function(canvas) {
        // console.log('Inside Canvas');
        canvas.getContext('2d');
        
        // console.log(canvas.height + ' ' + canvas.width);        
        
        // var imgData = canvas.toDataURL('image/jpeg', 1.0);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // var pdf = new jsPDF('portrait', 'pt',  [pdfWidth, pdfHeight]);
        // pdf.addImage(imgData, 'JPEG', topLeftMargin, topLeftMargin, canvasImageWidth, canvasImageHeight);
        
        // for (var i = 1; i <= totalPDFPages; i++) { 
        //     // console.log('AddPage', i);
        //     pdf.addPage([pdfWidth , pdfHeight], 'portrait');
        //     pdf.addImage(imgData, 'JPEG', topLeftMargin, - (pdfHeight * i) + (topLeftMargin * 4), canvasImageWidth, canvasImageHeight);
        // }

        // pdf.save("HTML-Document.pdf");
    });

    // element.remove();
};

// tslint:disable-next-line: no-anyW
export const HistoryFile = async (enquiryDetail: EnquiryDetail, sortDirection: string | null, currency: string, timezone: TimezoneOption[], 
                                  // tslint:disable-next-line: no-any
                                  leadDetailsHistory: TaskSummaryList,
                                  // tslint:disable-next-line: no-any
                                  onSuccess: (data: any) => void,
                                  customFieldByHierarchy: CustomFieldByHierarchy[]) => {

    // tslint:disable-next-line: no-console
    // console.log(enquiryCreatedString);    
    let documentBlob: Blob | undefined;
    const html = historyHtml(enquiryDetail, currency, timezone, leadDetailsHistory, customFieldByHierarchy);
    // // tslint:disable-next-line: no-console
    // console.log('html', html);

    var parser = new DOMParser();
    var htmlDocument = parser.parseFromString(html, 'text/html');
    let htmlElement = htmlDocument.body;

    var element = document.createElement('div');
    element.setAttribute('id', 'historyDiv');
    element.style.visibility = 'hidden';    
    element.appendChild(htmlElement); 
    document.body.appendChild(element);     

    // Without this the pages will break.
    getPdf(htmlElement, element);
    
    const doc = new jsPDF({
        orientation: 'p',
        format: 'a4',
        unit: 'pt'
    });

    doc.html(htmlElement, {
        margin: [15, 0, 15, 0],
        autoPaging: 'text',
        callback: function (currentDoc: jsPDF) {           
            // currentDoc.save();
            var pageCount = currentDoc.getNumberOfPages();
            currentDoc.deletePage(pageCount); // delete blank last pages.
            
            documentBlob = currentDoc.output('blob');

            // Following is for testing only
            // var blobPDF =  new Blob([documentBlob ], { type : 'application/pdf'});
            // var blobUrl = URL.createObjectURL(blobPDF);
            // window.open(blobUrl);

            // tslint:disable-next-line: no-console
            // console.log('documentBlob', documentBlob);
            onSuccess(documentBlob);

            element.remove();
            // return documentBlob;
            // currentDoc.saveGraphicsState
            // currentDoc.output('blob');
        },        
        x: 10,
        y: 10,
    });

    // return results;
};
